import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GoTo = makeShortcode("GoTo");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ground-rules"
    }}>{`Ground rules`}</h1>
    <h3 {...{
      "id": "6-one-issue-at-a-time"
    }}>{`6. One issue at a time.`}</h3>
    <p>{`Especially with conflict that is heated, it is easy to remember all of the things that are bothering you about the other person and to just add all of these to the current issue. It’s called ‘kitchen-sink fighting’ – everything is thrown in together. “Why didn’t you tell me you’d be home late tonight? Oh and you did none of the chores I asked you to do last week! Last month you ripped a hole in the jacket you borrowed from your brother but gave it back to him without letting him know!”.`}</p>
    <p>{`This can feel overwhelming for the teenager, and multiple assaults are bound to make them feel angry. Prioritise the issues, and deal with one issue at a time.`}</p>
    <h3 {...{
      "id": "7-communicate-well"
    }}>{`7. Communicate well.`}</h3>
    <p>{`If you get angry or dismissive, your teenager may respond by either zoning out of the conversation completely, or getting angry. This doesn’t mean you can’t express emotion, but remember that once emotions get a hold of you, the conversation won’t be very productive, and it can be hard to get back on track.`}</p>
    <p>
  You can find more information on how to communicate well with your teenager in the{' '}
  <GoTo to="/dashboard" mdxType="GoTo">
    <i>Connect</i>
  </GoTo>{' '}
  module.
    </p>
    <h3 {...{
      "id": "8-express-concern-for-their-welfare-rather-than-anger"
    }}>{`8. Express concern for their welfare, rather than anger.`}</h3>
    <p>{`Imagine that your teenager has broken their curfew, and you sat up until 12:30am waiting for them to come home. Are you really angry that they broke the curfew? Or are you more worried about what could have happened to them, out so late? Communicate this to your teenager. Tell them that you
are frustrated that they broke their curfew, but that your main concern is for their safety. It will show them that you’re motivated by your love and concern for their welfare rather than being a ‘control freak’.`}</p>
    <SingleImage smallGridSize={12} gridSize={8} src="/images/m5/22-01.svg" mdxType="SingleImage" />
    <h3 {...{
      "id": "9-timing-is-everything"
    }}>{`9. Timing is everything.`}</h3>
    <p>{`When the timing is wrong, you are almost guaranteed an angry reaction. Be sensitive to your environment and the other person’s moods. Choose a time when the person is not stressed or anxious or frustrated. When emotions
are getting out of control, take a break. Don’t insist on getting to a solution when you or your teenager are getting too emotionally worked up – it is more likely that someone will ‘dig their heels in’ or say something
hurtful. Try again when emotions have cooled and it is possible to talk in a civil way.`}</p>
    <SingleImage smallGridSize={12} gridSize={8} src="/images/m5/23-01.svg" mdxType="SingleImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      